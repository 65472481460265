<template>
  <b-container fluid>
    <b-table-simple
      bordered
      small
      hover
      foot-clone
      responsive
      sticky-header
      no-border-collapse
      table-class="mt-0"
      head-variant="white"
    >
      <b-thead>
        <b-tr>
          <b-th class="text-center" v-if="soyAdmin">Eliminar</b-th>
          <b-th class="text-center">Cheque</b-th>
          <b-th class="text-left">Pedido</b-th>
          <b-th class="text-left">Factura</b-th>
          <b-th class="text-left">Proveedor</b-th>
          <b-th class="text-left">Cliente</b-th>
          <b-th class="text-center">Fecha Factura</b-th>
          <b-th class="text-center">Vencimiento</b-th>
          <b-th class="text-center">Status</b-th>
          <b-th class="text-right">Total</b-th>
          <b-th class="text-right">Saldo Actual</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item, index) in listaFacturas"
          :key="index"
          :class="{ 'bg-warning': isSelected(index) }"
          @click="selectRow(item.FacturaID, index)"
        >
          <b-td v-if="soyAdmin" class="text-center">
            <b-button
              variant="outline-danger"
              size="sm"
              @click="eliminarFactura(item)"
              >X</b-button
            >
          </b-td>
          <b-td class="text-center"
            ><b-form-checkbox v-model="item.Cheque"></b-form-checkbox
          ></b-td>
          <b-td class="text-lrft">{{ item.pedido.codigo }}</b-td>
          <b-td class="text-left">{{ item.NumFactura }}</b-td>
          <b-td class="text-left">{{
            item.pedido.proveedor_proveedore.nombre_corto
          }}</b-td>
          <b-td class="text-left">{{
            item.pedido.cliente_usuario.razon_social
          }}</b-td>
          <b-td class="text-center">{{ parseDate(item.FechaEmision) }}</b-td>
          <b-td class="text-center">{{ parseDate(item.Vencimiento) }}</b-td>
          <b-td class="text-center">{{ item.Estado }}</b-td>
          <b-td class="text-right">{{ item.Total | moneda }}</b-td>
          <b-td class="text-right">{{ item.SaldoActual | moneda }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <b-modal
      id="modal-agregar-archivos"
      ref="modal-agregar-archivos"
      size="lg"
      title="Agregar Archivos"
      scrollable
      hide-footer
    >
      <ArchivoUploader
        v-bind:rutasArchivo="archivo.rutasArchivo"
        v-bind:documento_id="archivo.documento_id"
        v-bind:tipo_documento="archivo.tipo_documento"
        @reload-archivos="recargarArchivos"
        :aceptarMultiple="true"
      />
    </b-modal>

    <b-row>
      <b-col sm="3">
        <div>
          <b-input-group prepend="Compras Totales" size="sm">
            <b-input-group-prepend is-text><b>$</b></b-input-group-prepend>
            <b-form-input
              type="number"
              aria-label="Compras Totales"
              :value="comprasTotales"
              readonly
              class="bg-danger text-white"
            ></b-form-input>
          </b-input-group>
        </div>
      </b-col>
      <b-col sm="3">
        <div>
          <b-input-group prepend="Vencido" size="sm">
            <b-input-group-prepend is-text><b>$</b></b-input-group-prepend>
            <b-form-input
              type="number"
              aria-label="Compras Totales"
              :value="vencido"
              readonly
              class="bg-danger text-white"
            ></b-form-input>
          </b-input-group>
        </div>
      </b-col>
      <b-col sm="3">
        <div>
          <b-input-group prepend="Por Vencer" size="sm">
            <b-input-group-prepend is-text><b>$</b></b-input-group-prepend>
            <b-form-input
              type="number"
              aria-label="Compras Totales"
              :value="porVencer"
              readonly
              class="bg-danger text-white"
            ></b-form-input>
          </b-input-group>
        </div>
      </b-col>
      <b-col sm="3">
        <div>
          <b-input-group prepend="Vencido y Por Vencer" size="sm">
            <b-input-group-prepend is-text><b>$</b></b-input-group-prepend>
            <b-form-input
              type="number"
              aria-label="Compras Totales"
              :value="vencidoPorVencer"
              readonly
              class="bg-danger text-white"
            ></b-form-input>
          </b-input-group>
        </div>
      </b-col>
    </b-row>

    <div class="mt-0">
      <b-tabs content-class="mt-3" justified card>
        <b-tab title="Información de Factura" active class="bg-light p-0">
          <b-container fluid>
            <b-row>
              <!--COL 1 -->
              <b-col sm="4" class="">
                <b-row class="text-center">
                  <b-col cols="12">
                    <p
                      class="text-center text-white font-weight-bold bg-info p-1"
                    >
                      Cliente - Información
                    </p>
                  </b-col>
                </b-row>
                <b-form-row align-h="start">
                  <b-col>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="5"
                      label-size="sm"
                      label="Nombre del Cliente"
                    >
                      <b-form-input
                        size="sm"
                        v-model="infoFactura.cliente"
                        readonly
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row align-h="start">
                  <b-col>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="5"
                      label-size="sm"
                      label="Número de Factura"
                    >
                      <b-form-input
                        size="sm"
                        v-model="infoFactura.numeroFactura"
                        :readonly="!soyAdmin"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row align-h="start">
                  <b-col>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="5"
                      label-size="sm"
                      label="Descripción"
                    >
                      <b-form-input
                        size="sm"
                        v-model="infoFactura.descripcion"
                        :readonly="!soyAdmin"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row align-h="start">
                  <b-col>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="5"
                      label-size="sm"
                      label="Fecha Factura"
                    >
                      <b-form-input
                        size="sm"
                        v-model="infoFactura.fechaFactura"
                        readonly
                        type="date"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row align-h="start">
                  <b-col>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="5"
                      label-size="sm"
                      label="Fecha Vencimiento"
                    >
                      <b-form-input
                        size="sm"
                        v-model="infoFactura.fechaVencimiento"
                        type="date"
                        :readonly="!soyAdmin"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row align-h="start">
                  <b-col>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="5"
                      label-size="sm"
                      label="SubTotal"
                    >
                      <b-input-group prepend="$" size="sm"
                        ><b-form-input
                          size="sm"
                          type="number"
                          v-model="infoFactura.subTotal"
                          readonly
                          class="text-right"
                        ></b-form-input
                      ></b-input-group>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row align-h="start">
                  <b-col>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="5"
                      label-size="sm"
                      label="IVA"
                    >
                      <b-input-group prepend="$" size="sm"
                        ><b-form-input
                          size="sm"
                          type="number"
                          class="text-right"
                          v-model="infoFactura.iva"
                          readonly
                        ></b-form-input
                      ></b-input-group>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row align-h="start">
                  <b-col>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="5"
                      label-size="sm"
                      label="Total Factura"
                    >
                      <b-input-group prepend="$" size="sm"
                        ><b-form-input
                          size="sm"
                          type="number"
                          v-model="infoFactura.total"
                          readonly
                          class="bg-danger text-white text-right"
                        ></b-form-input
                      ></b-input-group>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-button
                  variant="outline-primary"
                  class="mb-4"
                  @click="updateFactura"
                  v-if="soyAdmin"
                  :disabled="loading"
                >
                  <b-spinner small v-if="loading"></b-spinner>
                  {{ loading ? "" : "Actualizar" }}
                </b-button>
              </b-col>
              <!--COL 2 -->
              <b-col sm="4">
                <b-row class="text-center">
                  <b-col cols="12">
                    <p
                      class="text-center text-white font-weight-bold bg-warning p-1"
                    >
                      Descuento a la Factura
                    </p>
                  </b-col>
                </b-row>
                <b-row
                  class="text-center"
                  v-if="!descuentosFactura || !descuentosFactura.length"
                >
                  <b-col cols="12">
                    <p
                      class="text-center text-white font-weight-bold bg-warning p-1"
                    >
                      Sin descuentos
                    </p>
                  </b-col>
                </b-row>
                <b-form-row
                  align-h="start"
                  v-else
                  v-for="descuento in descuentosFactura"
                  :key="descuento.DescuentoID"
                >
                  <b-col>
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="5"
                      label-size="sm"
                      :label="`${
                        tiposDescuentoArray[descuento.TipoDescuentoID]
                      }`"
                    >
                      <b-input-group prepend="$" size="sm">
                        <b-form-input
                          size="sm"
                          type="number"
                          class="text-right"
                          v-model="descuento.Importe"
                          readonly
                        >
                        </b-form-input>
                        <b-input-group-append>
                          <b-button variant="warning">
                            {{ descuento.FechaDescuento }}
                          </b-button>
                        </b-input-group-append>
                        <b-input-group-append>
                          <b-button
                            variant="warning"
                            @click="
                              abrirModalArchivoUploader(
                                descuento.DescuentoID,
                                'DESCUENTO'
                              )
                            "
                          >
                            Ver
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <b-form-textarea
                        id="textarea-plaintext"
                        plaintext
                        :value="descuento.Comentario"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </b-col>
              <!--COL 3 -->
              <b-col sm="4">
                <b-row class="text-center">
                  <b-col cols="12">
                    <p
                      class="text-center text-white font-weight-bold bg-info p-1"
                    >
                      Desglose de Pagos
                    </p>
                  </b-col>
                </b-row>
                <b-row
                  class="text-center"
                  v-if="pagosFactura && pagosFactura.length === 0"
                >
                  <b-col cols="12">
                    <p
                      class="text-center text-white font-weight-bold bg-info p-1"
                    >
                      Sin pagos
                    </p>
                  </b-col>
                </b-row>

                <b-form-group
                  v-if="
                    pagosFactura &&
                    pagosFactura[0] &&
                    parseInt(pagosFactura[0].Abono) === 0
                  "
                  label-cols="5"
                  label-cols-lg="5"
                  label-size="sm"
                  label="Pago Total"
                >
                  <b-input-group prepend="$" size="sm">
                    <b-form-input
                      size="sm"
                      type="text"
                      class="text-right"
                      v-model="pagosFactura[0].Importe"
                      readonly
                    >
                    </b-form-input>

                    <b-input-group-append>
                      <b-button variant="info">
                        {{ pagosFactura[0].FechaPago }}
                      </b-button>
                    </b-input-group-append>

                    <b-input-group-append>
                      <b-button
                        variant="info"
                        @click="
                          abrirModalArchivoUploader(pagosFactura[0].id, 'PAGO')
                        "
                      >
                        Ver
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <b-form-group
                  v-else
                  v-for="(pago, index) in pagosFactura"
                  :key="pago.id"
                  label-cols="5"
                  label-cols-lg="5"
                  label-size="sm"
                  :label="`Pago #${index + 1}`"
                >
                  <b-input-group prepend="$" size="sm">
                    <b-form-input
                      size="sm"
                      class="text-right"
                      type="number"
                      v-model="pago.Importe"
                      readonly
                    >
                    </b-form-input>
                    <b-input-group-append>
                      <b-button variant="info">
                        {{ pago.FechaPago }}
                      </b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button
                        variant="info"
                        @click="abrirModalArchivoUploader(pago.id, 'PAGO')"
                      >
                        Ver
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-textarea
                    id="textarea-plaintext"
                    plaintext
                    :value="pago.Comentario"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group
                  class="w-100"
                  label-cols="5"
                  label-cols-lg="5"
                  label-size="sm"
                  label="Saldo Actual"
                >
                  <b-input-group prepend="$" size="sm">
                    <b-form-input
                      size="sm"
                      type="number"
                      v-model="this.infoFactura.SaldoActual"
                      readonly
                      class="bg-success text-white"
                    >
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>

        <b-tab title="Pagos y Descuentos" v-if="soyAdmin" class="bg-light p-0">
          <b-container fluid class="p-0">
            <b-row>
              <b-col sm="6">
                <p
                  class="text-center text-white font-weight-bold bg-success p-1"
                >
                  Aplicar Pago, Abonos o Descuentos
                </p>
              </b-col>
              <b-col sm="3">
                <b-alert
                  variant="success"
                  show
                  v-if="parseFloat(saldoAFavor) > 0"
                  >El cliente cuenta con ${{ saldoAFavor }} de saldo a
                  favor.</b-alert
                >
              </b-col>
              <b-col sm="3">
                <b-button-group size="sm" class="d-flex">
                  <template v-if="parseFloat(saldoAFavor) > 0">
                    <b-button
                      variant="outline-success"
                      @click="pagoConSaldo(true)"
                      v-if="!pagoNuevo.ImporteBloqueado"
                    >
                      Usar saldo a favor
                    </b-button>
                    <b-button
                      v-else
                      variant="outline-success"
                      @click="pagoConSaldo(false)"
                    >
                      Cancelar pago con saldo
                    </b-button>
                  </template>
                  <b-button variant="outline-success" @click="pdfExport"
                    >Exportar a PDF</b-button
                  >
                  <b-button variant="outline-success" @click="excelExport"
                    >Exportar a Excel</b-button
                  >
                </b-button-group>
              </b-col>
            </b-row>
          </b-container>

          <b-container fluid>
            <b-row>
              <b-col sm="6">
                <fieldset>
                  <legend>Agregar Descuento</legend>
                  <b-row>
                    <b-col sm="6">
                      <label for="descuentoNuevo">Importe</label>

                      <b-input-group prepend="$">
                        <b-form-input
                          id="descuentoNuevo"
                          placeholder="100.00"
                          v-model="descuentoNuevo.Importe"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>

                    <b-col sm="6">
                      <label for="tipoDescuento">Tipo Descuento</label>

                      <b-form-select
                        id="tipoDescuento"
                        :options="tiposDescuento.options"
                        v-model="descuentoNuevo.TipoID"
                      ></b-form-select>
                    </b-col>
                  </b-row>
                  <label for="comentarioDescuento" class="mt-4"
                    >Comentario</label
                  >

                  <b-form-textarea
                    id="comentarioDescuento"
                    v-model="descuentoNuevo.Comentario"
                  ></b-form-textarea>
                  <b-row class="my-4">
                    <b-col sm="6">
                      <label for="fotosDescuento">Imágenes</label>

                      <b-form-file
                        id="fotosDescuento"
                        v-model="descuentoNuevo.Imagen"
                        placeholder="Seleccione una imagen o arrastrela aquí..."
                        drop-placeholder="Suelte la imagen aquí"
                        accept="image/jpeg, image/png, image/gif"
                      ></b-form-file>
                      <div class="mt-3">
                        {{
                          descuentoNuevo.Imagen
                            ? descuentoNuevo.Imagen.name
                            : ""
                        }}
                      </div>
                    </b-col>
                    <b-col sm="6">
                      <label for="pdfDescuento">PDF</label>

                      <b-form-file
                        id="pdfDescuento"
                        v-model="descuentoNuevo.PDF"
                        placeholder="Seleccione un PDF o arrastrelo aquí..."
                        drop-placeholder="Suelte el PDF aquí"
                        accept="application/pdf"
                      ></b-form-file>
                      <div class="mt-3">
                        {{ descuentoNuevo.PDF ? descuentoNuevo.PDF.name : "" }}
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="6"> </b-col>
                    <b-col sm="6">
                      <b-button
                        variant="primary"
                        block
                        :disabled="!isBtnSaveActive"
                        @click="postDiscount"
                        >Guardar</b-button
                      >
                    </b-col>
                  </b-row>
                </fieldset>
              </b-col>
              <b-col sm="6">
                <fieldset>
                  <legend>Agregar Pago</legend>
                  <b-row
                    v-if="
                      pagoNuevo.MetodoPagoID != 1 && pagoNuevo.MetodoPagoID != 2
                    "
                  >
                    <b-col sm="6">
                      <label for="importePago">Importe</label>

                      <b-input-group prepend="$">
                        <b-form-input
                          id="importePago"
                          placeholder="100.00"
                          v-model.number="pagoNuevo.Importe"
                          :disabled="pagoNuevo.ImporteBloqueado"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>

                    <b-col sm="6">
                      <label for="metodoPago">Método de pago</label>

                      <b-form-select
                        id="metodoPago"
                        :options="metodosPago.options"
                        v-model="pagoNuevo.MetodoPagoID"
                      ></b-form-select>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col sm="4">
                      <label for="importePago">Importe</label>

                      <b-input-group prepend="$">
                        <b-form-input
                          id="importePago"
                          placeholder="100.00"
                          v-model="pagoNuevo.Importe"
                          :disabled="pagoNuevo.ImporteBloqueado"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>

                    <b-col sm="4">
                      <label for="metodoPago">Método de pago</label>

                      <b-form-select
                        id="metodoPago"
                        @change="
                          () => {
                            pagoNuevo.NumeroReferencia = null;
                          }
                        "
                        :options="metodosPago.options"
                        v-model="pagoNuevo.MetodoPagoID"
                      ></b-form-select>
                    </b-col>
                    <b-col sm="4">
                      <label for="numeroReferencia">Número de referencia</label>

                      <b-form-input
                        id="numeroReferencia"
                        type="text"
                        v-model="pagoNuevo.NumeroReferencia"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="my-4">
                    <b-col sm="6">
                      <label for="fechaPago">Fecha de pago</label>

                      <b-form-input
                        id="fechaPago"
                        type="date"
                        v-model="pagoNuevo.FechaPago"
                      ></b-form-input>
                    </b-col>

                    <b-col sm="6">
                      <label for="aplicadoPago">Fecha de aplicación</label>

                      <b-form-input
                        id="aplicadoPago"
                        type="date"
                        v-model="pagoNuevo.FechaAplicado"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12">
                      <label for="comentarioPago">Comentario</label>

                      <b-form-textarea
                        id="comentarioPago"
                        v-model="pagoNuevo.Comentario"
                      ></b-form-textarea>
                    </b-col>
                  </b-row>
                  <b-row class="my-4">
                    <b-col sm="6">
                      <label for="fotosPago">Imágenes</label>

                      <b-form-file
                        id="fotosPago"
                        v-model="pagoNuevo.Imagen"
                        placeholder="Seleccione una imagen o arrastrela aquí..."
                        drop-placeholder="Suelte la imagen aquí"
                        accept="image/jpeg, image/png, image/gif"
                      ></b-form-file>
                      <div class="mt-3">
                        {{ pagoNuevo.Imagen ? pagoNuevo.Imagen.name : "" }}
                      </div>
                    </b-col>
                    <b-col sm="6">
                      <label for="pdfPago">PDF</label>

                      <b-form-file
                        id="pdfPago"
                        v-model="pagoNuevo.PDF"
                        placeholder="Seleccione un PDF o arrastrelo aquí..."
                        drop-placeholder="Suelte el PDF aquí"
                        accept="application/pdf"
                      ></b-form-file>
                      <div class="mt-3">
                        {{ pagoNuevo.PDF ? pagoNuevo.PDF.name : "" }}
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="6"> </b-col>
                    <b-col sm="6">
                      <b-button
                        variant="primary"
                        block
                        @click="postPayment"
                        :disabled="!isBtnSaveActive"
                        >Guardar</b-button
                      >
                    </b-col>
                  </b-row>
                </fieldset>
              </b-col>
            </b-row>
          </b-container>

          <b-container fluid>
            <b-table-simple bordered small responsive fixed>
              <b-thead>
                <b-tr>
                  <b-th class="text-center">Fecha</b-th>
                  <b-th class="text-center">Importe</b-th>
                  <b-th class="text-center">Metodo de pago</b-th>
                  <b-th class="text-center">Comentarios</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="pago in movimientosFactura" :key="pago.PagoID">
                  <b-td class="text-center">{{ pago.FechaPago }}</b-td>
                  <b-td class="text-right">{{ `$${pago.Importe}` }}</b-td>
                  <b-td class="text-center">{{
                    pago.MetodoPago.Descripcion
                  }}</b-td>
                  <b-td>{{ pago.Comentario }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-container>
        </b-tab>

        <b-tab title="Productos" class="bg-light p-0">
          <b-container fluid>
            <b-row>
              <b-col>
                <b-table-simple bordered small responsive>
                  <b-thead>
                    <b-tr>
                      <b-th class="text-center">Partida</b-th>
                      <b-th class="text-center">Código</b-th>
                      <b-th class="text-center">Subcódigo</b-th>
                      <b-th class="text-center">Descripción</b-th>
                      <b-th class="text-center">Cant.Pedida</b-th>
                      <b-th class="text-center">Cant.Surtida</b-th>
                      <b-th class="text-center">$ Precio</b-th>
                      <b-th class="text-center">$ Subtotal</b-th>
                      <b-th class="text-center">$ Total C/IVA</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(item, key, index) in facturaProductos"
                      :key="index"
                    >
                      <b-td class="text-center">{{ item.Partida }}</b-td>
                      <b-td class="text-center">{{
                        item.Producto.codigo
                      }}</b-td>
                      <b-td class="text-center">{{
                        item.Producto.subcodigo
                      }}</b-td>
                      <b-td>{{ item.Producto.descripcion }}</b-td>
                      <b-td class="text-center">{{ item.CantidadPedida }}</b-td>
                      <b-td class="text-center">{{
                        item.CantidadSurtida
                      }}</b-td>
                      <b-td class="text-right">{{ item.Precio }}</b-td>
                      <b-td class="text-right">{{
                        (item.CantidadSurtida * item.Precio).toFixed(2)
                      }}</b-td>
                      <b-td class="text-right">{{
                        (
                          item.CantidadSurtida *
                          item.Precio *
                          (1 + item.Producto.iva)
                        ).toFixed(2)
                      }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs>
    </div>
  </b-container>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { jsPDF } from "jspdf";
import sweetalert from "sweetalert2";
import api from "../../../utils/api.js";
import { axiosInstance } from "../../../utils/axiosInstance";
import ArchivoUploader from "../../../components/ArchivoUploader.vue";

export default {
  data() {
    return {
      stickyHeader: true,
      noCollapse: true,
      dummyCurrency: 0.0,
      dummyText: "XYZ",
      selectedRow: null,
      infoFactura: {
        FacturaID: null,
        cliente: "",
        numeroFactura: "",
        descripcion: "",
        fechaFactura: "",
        fechaVencimiento: "",
        SaldoActual: 0.0,
        saldoDespuesDescuentos: 0.0,
        subTotal: 0.0,
        iva: 0.0,
        total: 0.0,
      },
      descuentosFactura2: {
        financiero: { TipoDescuentoID: 1, Importe: 0.0, Comentario: "" },
        faltante: { TipoDescuentoID: 2, Importe: 0.0, Comentario: "" },
        devolucion: { TipoDescuentoID: 3, Importe: 0.0, Comentario: "" },
        mercanciaDefectuosa: {
          TipoDescuentoID: 4,
          Importe: 0.0,
          Comentario: "",
        },
        diferencia: { TipoDescuentoID: 5, Importe: 0.0, Comentario: "" },
        fletes: { TipoDescuentoID: 6, Importe: 0.0, Comentario: "" },
        mercanciaNoSolicitada: {
          TipoDescuentoID: 7,
          Importe: 0.0,
          Comentario: "",
        },
      },
      archivo: {
        rutasArchivo: [],
        documento_id: null,
        tipo_documento: null,
      },
      imagen: {
        rutasImagen: [],
        documento_id: null,
        tipo_documento: null,
      },
      metodosPago: {
        selected: null,
        options: [],
      },
      pagoNuevo: {
        FacturaID: null,
        MetodoPagoID: null,
        Importe: null,
        NumeroReferencia: null,
        FechaPago: null,
        FechaAplicado: null,
        Comentario: "",
        Pago: null,
        Imagen: null,
        PDF: null,
        PagoConSaldo: false,
        ImporteBloqueado: false,
      },
      descuentoNuevo: {
        FacturaID: null,
        TipoID: null,
        Importe: 0,
        Comentario: "",
        Imagen: null,
        PDF: null,
      },
      tiposDescuento: {
        selected: null,
        options: [],
      },
      tiposDescuentoArray: [],
      isBtnSaveActive: true,
      pagosFactura: [],
      movimientosFactura: [],
      descuentosFactura: [],
      facturaProductos: [],
      soyAdmin: false,
      usuario: "",
      saldoAFavor: 0,
      loading: false,
    };
  },
  emits: ["actualizar"],
  async mounted() {
    this.soyAdmin = sessionStorage.getItem("admin") === "true";
    this.usuario = sessionStorage.getItem("usuario_id");

    try {
      // métodos de pago
      const metodosPago = await axiosInstance.get("metodosPago", {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      this.metodosPago.options = [{ value: null, text: "Seleccione tipo" }];

      for (let metodo of metodosPago.data) {
        this.metodosPago.options.push({
          value: metodo.MetodoID,
          text: metodo.Descripcion,
        });
      }
    } catch (error) {
      this.makeToast(
        "Metodos pago",
        "Error al cargar los metodos de pago",
        "danger"
      );
    }

    try {
      // tipos de descuento
      const tiposDescuento = await axiosInstance.get("tipos-descuento", {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      this.tiposDescuento.options = [{ value: null, text: "Seleccione tipo" }];

      for (let tipo of tiposDescuento.data) {
        this.tiposDescuento.options.push({
          value: tipo.TipoID,
          text: tipo.descripcion,
        });
        this.tiposDescuentoArray[tipo.TipoID] = tipo.descripcion;
      }
    } catch (error) {
      this.makeToast(
        "Tipos Descuento",
        "Error al cargar los tipos de descuento",
        "danger"
      );
    }
  },
  components: {
    ArchivoUploader,
  },
  computed: {
    ...mapState("facturas", ["listaFacturas", "isBusy"]),
    comprasTotales() {
      let total = 0;
      for (let factura of this.listaFacturas) {
        total += parseFloat(factura.Total);
      }
      return total.toFixed(2);
    },
    vencido() {
      let vencido = 0;
      for (let factura of this.listaFacturas) {
        if (
          factura.Estado === "N" &&
          moment(factura.Vencimiento).diff(moment()) < 0
        ) {
          vencido += parseFloat(factura.SaldoActual);
        }
      }
      return vencido.toFixed(2);
    },
    porVencer() {
      let porVencer = 0;
      for (let factura of this.listaFacturas) {
        if (
          factura.Estado === "N" &&
          moment(factura.Vencimiento).diff(moment()) >= 0
        ) {
          porVencer += parseFloat(factura.SaldoActual);
        }
      }
      return porVencer.toFixed(2);
    },
    vencidoPorVencer() {
      return (parseFloat(this.vencido) + parseFloat(this.porVencer)).toFixed(2);
    },
  },
  methods: {
    ...mapActions("facturas", ["consultarFacturasAction"]),
    selectRow(FacturaID, index) {
      this.resetDetails();
      this.selectedRow = index;
      api("GET", "facturas", {}, { FacturaID })
        .then(async (response) => {
          const factura = response[0];
          this.infoFactura.FacturaID = factura.FacturaID;
          this.infoFactura.cliente =
            factura.pedido.cliente_usuario.razon_social ||
            factura.pedido.cliente_usuario.nombre;
          this.infoFactura.numeroFactura = factura.NumFactura;
          this.infoFactura.descripcion = factura.Concepto;
          this.infoFactura.fechaFactura = moment(
            factura.FechaEmision.split("T")[0]
          ).format("YYYY-MM-DD");
          this.infoFactura.fechaVencimiento = moment(
            factura.Vencimiento.split("T")[0]
          ).format("YYYY-MM-DD");
          this.infoFactura.subTotal = factura.Subtotal;
          this.infoFactura.iva = factura.IVA;
          this.infoFactura.total = factura.Total;
          this.infoFactura.SaldoActual = factura.SaldoActual;
          // productos
          this.facturaProductos = factura.factura_productos.map((item) => {
            return {
              ...item,
            };
          });
          // descuentos
          this.descuentosFactura = factura.descuentos_facturas.map(
            (descuento) => {
              return {
                ...descuento,
                Importe: descuento.Importe,
                FechaDescuento: moment(descuento.FechaCreacion).format(
                  "YYYY/MM/DD"
                ),
              };
            }
          );
          // pagos
          const pagos = await axiosInstance.get("movimientos-cobranza", {
            params: { FacturaID: this.infoFactura.FacturaID },
            headers: {
              authorization: localStorage.getItem("token"),
            },
          });
          this.pagosFactura = pagos.data
            .map((pago) => {
              return {
                ...pago,
                Importe: pago.Pago
                  ? `${(parseFloat(pago.Importe) * -1).toFixed(2)}`
                  : pago.Importe,
                FechaPago: moment(pago.FechaCreacion.split("T")[0]).format(
                  "YYYY/MM/DD"
                ),
              };
            })
            .filter((pago) => pago.Pago);

          this.movimientosFactura = pagos.data.map((pago) => {
            return {
              ...pago,
              FechaPago: moment(pago.FechaCreacion.split("T")[0]).format(
                "YYYY/MM/DD"
              ),
            };
          });

          this.saldoAFavor = (
            await axiosInstance.get("movimientos-cobranza/saldo", {
              headers: {
                authorization: localStorage.getItem("token"),
              },
              params: {
                ClienteID: factura.pedido.cliente,
                ProveedorID: factura.pedido.proveedor,
              },
            })
          ).data.saldoTotal;
        })
        .catch((error) => {
          this.makeToast(
            "Cargar Factura",
            `Error en el consumo: ${error}`,
            "danger"
          );
        });
    },
    isSelected(index) {
      return this.selectedRow === index;
    },
    resetDetails() {
      this.selectedRow = null;
      this.infoFactura = {
        cliente: "",
        numeroFactura: "",
        descripcion: "",
        fechaFactura: "",
        fechaVencimiento: "",
        SaldoActual: 0.0,
        saldoDespuesDescuentos: 0.0,
        subTotal: 0.0,
        iva: 0.0,
        total: 0.0,
      };
      this.descuentosFactura = [];
      this.descuentosFactura2 = {
        financiero: { TipoDescuentoID: 1, Importe: 0.0, Comentario: "" },
        faltante: { TipoDescuentoID: 2, Importe: 0.0, Comentario: "" },
        devolucion: { TipoDescuentoID: 3, Importe: 0.0, Comentario: "" },
        mercanciaDefectuosa: {
          TipoDescuentoID: 4,
          Importe: 0.0,
          Comentario: "",
        },
        diferencia: { TipoDescuentoID: 5, Importe: 0.0, Comentario: "" },
        fletes: { TipoDescuentoID: 6, Importe: 0.0, Comentario: "" },
        mercanciaNoSolicitada: {
          TipoDescuentoID: 7,
          Importe: 0.0,
          Comentario: "",
        },
      };
      this.facturaProductos = [];
      this.pagoNuevo = {
        FacturaID: null,
        MetodoPagoID: null,
        Completo: false,
        Abono: false,
        Importe: 0,
        FechaPago: null,
        FechaAplicado: null,
        Comentario: "",
        pagoConSaldo: false,
      };
      this.pagosFactura = null;
      this.descuentos = {
        financiero: { TipoDescuentoID: 1, Importe: 0.0, Comentario: "" },
        faltante: { TipoDescuentoID: 2, Importe: 0.0, Comentario: "" },
        devolucion: { TipoDescuentoID: 3, Importe: 0.0, Comentario: "" },
        mercanciaDefectuosa: {
          TipoDescuentoID: 4,
          Importe: 0.0,
          Comentario: "",
        },
        diferencia: { TipoDescuentoID: 5, Importe: 0.0, Comentario: "" },
        fletes: { TipoDescuentoID: 6, Importe: 0.0, Comentario: "" },
        mercanciaNoSolicitada: {
          TipoDescuentoID: 7,
          Importe: 0.0,
          Comentario: "",
        },
      };
      this.saldoAFavor = 0;
    },
    async postPayment() {
      if (!this.infoFactura.FacturaID) {
        this.makeToast("Guardar Pago", "No ha seleccionado factura", "danger");
        return;
      }

      if (this.infoFactura.SaldoActual == 0) {
        this.makeToast("Guardar Pago", "La factura ya esta pagada", "danger");
        return;
      }

      if (this.pagoNuevo.Importe <= 0) {
        this.makeToast(
          "Guardar Pago",
          "Especifique un importe mayor a 0",
          "danger"
        );
        return;
      }

      if (!this.pagoNuevo.MetodoPagoID) {
        this.makeToast(
          "Guardar Pago",
          "Especifique un metodo de pago",
          "danger"
        );
        return;
      }

      if (
        this.pagoNuevo.MetodoPagoID != 3 &&
        (!this.pagoNuevo.NumeroReferencia ||
          this.pagoNuevo.NumeroReferencia === "")
      ) {
        this.makeToast(
          "Guardar Pago",
          "Especifique un número de referencia",
          "danger"
        );
        return;
      }

      if (!this.pagoNuevo.FechaPago) {
        this.makeToast(
          "Guardar Pago",
          "Especifique una fecha de pago",
          "danger"
        );
        return;
      }

      if (!this.pagoNuevo.FechaAplicado) {
        this.makeToast(
          "Guardar Pago",
          "Especifique una fecha de aplicacion",
          "danger"
        );
        return;
      }

      if (this.pagoNuevo.Comentario.length > 300) {
        this.makeToast(
          "Guardar Pago",
          "El comentario no puede ser mayor a 300 caracteres.",
          "warning",
          2500
        );
        return;
      }

      this.pagoNuevo.FacturaID = this.infoFactura.FacturaID;

      if (this.pagosFactura.length === 0) {
        this.pagoNuevo.Abono =
          this.pagoNuevo.Importe === this.infoFactura.SaldoActual ? 0 : 1;
      } else {
        this.pagoNuevo.Abono = 1;
      }

      this.isBtnSaveActive = false;

      try {
        const response = await axiosInstance.post(
          "movimientos-cobranza",
          this.pagoNuevo,
          {
            headers: {
              authorization: localStorage.getItem("token"),
            },
          }
        );
        const pagoResponse = response.data;

        // Guardar imagen && pdf
        const tipo_documento = "PAGO";
        const documento_id = "id" in pagoResponse ? pagoResponse.id : null;
        const personal_id = "";
        const imagen = this.pagoNuevo.Imagen;
        const pdf = this.pagoNuevo.PDF;

        const formData = new FormData();
        formData.append("tipo_documento", tipo_documento);
        formData.append("documento_id", documento_id);
        formData.append("personal_id", personal_id);

        if (imagen && documento_id) {
          formData.append("archivo", imagen);
          await axiosInstance.post("archivos/agregar", formData, {
            headers: {
              authorization: localStorage.getItem("token"),
            },
          });
        }

        if (pdf && documento_id) {
          formData.set("archivo", pdf);
          await axiosInstance.post("archivos/agregar", formData, {
            headers: {
              authorization: localStorage.getItem("token"),
            },
          });
        }

        this.makeToast("Guardar Pago", "Pago guardado con exito.", "success");
        this.pagoNuevo = {
          FacturaID: null,
          MetodoPagoID: null,
          Completo: false,
          Abono: false,
          Importe: 0,
          FechaPago: null,
          FechaAplicado: null,
          Comentario: "",
        };
        this.isBtnSaveActive = true;
        this.selectRow(this.infoFactura.FacturaID, this.selectedRow);
      } catch (error) {
        this.makeToast(
          "Guardar Pago",
          "Error al guardar el pago:" + error,
          "danger"
        );
        this.isBtnSaveActive = true;
      }
    },
    async postDiscount() {
      if (!this.infoFactura.FacturaID) {
        this.makeToast(
          "Guardar Descuento",
          "No ha seleccionado factura",
          "danger"
        );
        return;
      }

      if (this.infoFactura.SaldoActual == 0) {
        this.makeToast(
          "Guardar Descuento",
          "La factura ya esta pagada",
          "danger"
        );
        return;
      }

      if (this.descuentoNuevo.Importe <= 0) {
        this.makeToast(
          "Guardar Descuento",
          "Especifique un importe mayor a 0",
          "danger"
        );
        return;
      }

      if (!this.descuentoNuevo.TipoID) {
        this.makeToast(
          "Guardar Descuento",
          "Especifique un tipo de descuento",
          "danger"
        );
        return;
      }

      // validamos descuento no sea mayor al saldo actual
      if (
        parseFloat(this.descuentoNuevo.Importe) > this.infoFactura.SaldoActual
      ) {
        this.makeToast(
          "Aplicar Descuento",
          `El importe (${this.descuentoNuevo.Importe}) es mayor al saldo actual de la factura (${this.infoFactura.SaldoActual})`,
          "danger",
          5000
        );
        return;
      }

      if (this.descuentoNuevo.Comentario.length > 300) {
        this.makeToast(
          "Guardar Descuento",
          "El comentario no puede ser mayor a 300 caracteres.",
          "warning",
          2500
        );
        return;
      }

      this.descuentoNuevo.FacturaID = this.infoFactura.FacturaID;

      // validar descuentos duplicados
      const items = Object.values(this.descuentosFactura2);
      for (let i = 0; i < items.length; i++) {
        if (parseFloat(items[i].Importe) > 0) {
          if (this.descuentoNuevo.TipoID === items[i].TipoDescuentoID) {
            this.makeToast(
              "Aplicar Descuento",
              "La factura ya cuenta con un descuento de este tipo",
              "danger",
              5000
            );
            return;
          }
        }
      }

      this.isBtnSaveActive = false;

      const payload = {
        FacturaID: this.infoFactura.FacturaID,
        descuentos: [
          {
            TipoDescuentoID: this.descuentoNuevo.TipoID,
            Importe: parseFloat(this.descuentoNuevo.Importe),
            Comentario: this.descuentoNuevo.Comentario,
          },
        ],
      };

      try {
        // Guardar Descuento
        const response = await axiosInstance.post("descuentos", payload, {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        });
        const descuentoResponse = response.data;

        // Guardar imagen && pdf
        const tipo_documento = "DESCUENTO";
        const documento_id =
          "data" in descuentoResponse
            ? descuentoResponse.data.map(
                (descuento) => descuento.DescuentoID
              )[0]
            : null;
        const personal_id = "";
        const imagen = this.descuentoNuevo.Imagen;
        const pdf = this.descuentoNuevo.PDF;

        const formData = new FormData();
        formData.append("tipo_documento", tipo_documento);
        formData.append("documento_id", documento_id);
        formData.append("personal_id", personal_id);

        if (imagen && documento_id) {
          formData.append("archivo", imagen);
          await axiosInstance.post("archivos/agregar", formData, {
            headers: {
              authorization: localStorage.getItem("token"),
            },
          });
        }

        if (pdf && documento_id) {
          formData.set("archivo", pdf);
          await axiosInstance.post("archivos/agregar", formData, {
            headers: {
              authorization: localStorage.getItem("token"),
            },
          });
        }

        // Recargar lista de facturas
        this.makeToast(
          "Aplicar Descuento",
          "Descuento guardado con exito.",
          "success"
        );
        document.querySelector("#btnBuscar").click();
        this.isBtnSaveActive = true;
        this.descuentoNuevo = {
          FacturaID: null,
          TipoID: null,
          Importe: 0,
          Comentario: "",
          Imagen: null,
          PDF: null,
        };
        this.selectRow(this.infoFactura.FacturaID, this.selectedRow);
      } catch (error) {
        this.makeToast(
          "Aplicar Descuento",
          "Error al guardar el descuento: " + error,
          "danger"
        );
        this.isBtnSaveActive = true;
      }
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || "Toast body content";
      this.$bvToast.toast(body, {
        title: title || "default",
        toaster: "b-toaster-top-center",
        variant: variant || "default",
        autoHideDelay: duration,
        solid: true,
      });
    },
    imprimir(aImprimir) {
      alert(aImprimir);
    },
    recargarArchivos() {
      this.getRutasArchivos(
        this.archivo.documento_id,
        this.archivo.tipo_documento
      );
    },
    async abrirModalArchivoUploader(documento_id, tipo_documento) {
      if (!documento_id) {
        this.makeToast(
          "Imagenes",
          `No ha seleccionado un documento (${tipo_documento})`,
          "danger",
          3000
        );
        return;
      }

      this.archivo.documento_id = documento_id;
      this.archivo.tipo_documento = tipo_documento;
      await this.getRutasArchivos(
        this.archivo.documento_id,
        this.archivo.tipo_documento
      );

      this.$refs["modal-agregar-archivos"].show();
    },
    getRutasArchivos(documento_id, tipo_documento) {
      return api(
        "GET",
        `archivos/obtener-archivos/${documento_id}/${tipo_documento}`
      )
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Archivos no encontrados",
              text: "Error del servidor",
              type: "error",
            });
            this.archivo.rutasArchivo = [];
          } else {
            this.archivo.rutasArchivo = [...result];
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            group: "foo",
            title: "Error al obtener las rutas de los archivos",
            text: "Error del servidor",
            type: "error",
          });
        });
    },
    recargarImagenes() {
      this.getRutasImagenes(
        this.imagen.documento_id,
        this.imagen.tipo_documento
      );
    },
    getRutasImagenes(documento_id, tipo_documento) {
      api("GET", `imagenes/obtener-imagenes/${documento_id}/${tipo_documento}`)
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Imagenes no encontradas",
              text: "Error del servidor",
              type: "error",
            });
            this.imagen.rutasImagen = [];
          } else {
            this.imagen.rutasImagen = [...result];
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            group: "foo",
            title: "Error al obtener las rutas de las imagenes",
            text: "Error del servidor",
            type: "error",
          });
        });
    },
    async abrirModalImageUploader(documento_id, tipo_documento) {
      if (!documento_id) {
        this.makeToast(
          "Imagenes",
          `No ha seleccionado un documento (${tipo_documento})`,
          "danger",
          3000
        );
        return;
      }

      this.imagen.documento_id = documento_id;
      this.imagen.tipo_documento = tipo_documento;
      await this.getRutasImagenes(
        this.imagen.documento_id,
        this.imagen.tipo_documento
      );
      this.$refs["modal-agregar-imagenes"].show();
    },
    async pdfExport() {
      if (!this.infoFactura.FacturaID) {
        this.makeToast(
          "Selección de factura",
          "Debe tener una factura seleccionada primero",
          "warning",
          2000
        );
        return;
      }

      try {
        const {
          data: { pagos, descuentos, factura, cliente, proveedor },
        } = await axiosInstance.get("facturas/pdf", {
          params: { FacturaID: this.infoFactura.FacturaID },
          headers: {
            authorization: localStorage.getItem("token"),
          },
        });

        const doc = new jsPDF();

        doc.setFont("helvetica", "bold");
        doc.setFontSize(20);
        doc.text(
          "Estado de cuenta de factura del cliente",
          10,
          10,
          { align: "left" },
          { bold: true }
        );
        doc.text("JP Representaciones", 10, 30);

        doc.setFontSize(10);
        doc.text("Nombre del cliente:", 10, 50);

        doc.setFont("helvetica", "normal");
        doc.text(`${cliente}`, 55, 50);

        doc.setFont("helvetica", "bold");
        doc.text("Nombre del proveedor:", 10, 55);

        doc.setFont("helvetica", "normal");
        doc.text(`${proveedor}`, 55, 55);

        const data = [
          {
            ["Factura"]: factura.NumFactura,
            ["Fecha"]: moment(factura.FechaEmision).format("DD/MM/YYYY"),
            ["Descripcion"]: factura.Concepto,
            ["Vencimiento"]: moment(factura.Vencimiento).format("DD/MM/YYYY"),
            ["Saldo Inicial"]: `$${factura.Total}`,
            ["Saldo Actual"]: `$${factura.Total}`,
          },
        ];

        let saldo = factura.Total;

        if (descuentos.length > 0) {
          for (const descuento of descuentos) {
            saldo = (parseFloat(saldo) - parseFloat(descuento.Importe)).toFixed(
              2
            );

            data.push({
              ["Factura"]: factura.NumFactura,
              ["Fecha"]: moment(descuento.FechaCreacion).format("DD/MM/YYYY"),
              ["Descripcion"]:
                descuento.Comentarios ||
                `Descuento de tipo ${descuento.TipoDescuento.descripcion}`,
              ["Vencimiento"]: " ",
              ["Saldo Inicial"]: `$${descuento.Importe}`,
              ["Saldo Actual"]: `$${saldo}`,
            });
          }
        } else {
          data.push({
            ["Factura"]: factura.NumFactura,
            ["Fecha"]: " ",
            ["Descripcion"]: "DCTO PACTADO",
            ["Vencimiento"]: " ",
            ["Saldo Inicial"]: "$0.00",
            ["Saldo Actual"]: `$${saldo}`,
          });
        }

        if (pagos.length > 0) {
          for (const pago of pagos) {
            saldo = (parseFloat(saldo) - parseFloat(-pago.Importe)).toFixed(2);
            data.push({
              ["Factura"]: factura.NumFactura,
              ["Fecha"]: moment(pago.FechaPago).format("DD/MM/YYYY"),
              ["Descripcion"]:
                pago.Comentarios ||
                `Pago con método ${pago.MetodoPago.Descripcion}`,
              ["Vencimiento"]: " ",
              ["Saldo Inicial"]: `$${parseFloat(-pago.Importe).toFixed(2)}`,
              ["Saldo Actual"]: `$${saldo}`,
            });
          }
        }

        doc.table(
          10,
          60,
          data,
          [
            {
              name: "Factura",
              align: "center",
              width: "35",
            },
            {
              name: "Fecha",
              align: "center",
              width: "30",
            },
            {
              name: "Descripcion",
              align: "left",
              width: "45",
            },
            {
              name: "Vencimiento",
              align: "center",
              width: "45",
            },
            {
              name: "Saldo Inicial",
              align: "right",
              width: "45",
            },
            {
              name: "Saldo Actual",
              align: "right",
              width: "45",
            },
          ],
          {
            headerBackgroundColor: "#808080",
            printHeaders: true,
            autoSize: false,
            fontSize: 8,
          }
        );

        doc.save(`Factura - ${factura.NumFactura}`);
      } catch (error) {
        this.makeToast(
          "Error en exportación",
          "Sucedió un error al intentar exportar el estado de la factura",
          "error",
          3000
        );
      }
    },
    async excelExport() {
      if (!this.infoFactura || !this.infoFactura.FacturaID) {
        this.makeToast(
          "Factura seleccionada",
          "Seleccione una factura para exportar a Excel",
          "warning",
          2000
        );
        return;
      }
      try {
        const stream = await axiosInstance.get("facturas/excel", {
          params: { FacturaID: this.infoFactura.FacturaID },
          responseType: "blob",
          headers: {
            authorization: localStorage.getItem("token"),
          },
        });

        const url = window.URL.createObjectURL(new Blob([stream.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `FacturaJPR-${this.infoFactura.numeroFactura}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.makeToast(
          "Error en exportación",
          "Hubo un error al exportar la factura a Excel",
          "error",
          2000
        );
      }
    },
    async eliminarFactura(factura) {
      try {
        const result = await sweetalert.fire({
          title: "¿Seguro que quieres borrar la factura y todo lo relacionado?",
          showCancelButton: true,
          confirmButtonText: "Eliminar",
        });

        if (!result.isConfirmed) {
          return;
        }

        await axiosInstance
          .delete(`facturas/${factura.FacturaID}`, {
            headers: { authorization: localStorage.getItem("token") },
          })
          .then(() => {
            this.makeToast(
              "Factura eliminada.",
              "Se ha eliminado todo lo relacionado a la factura.",
              "success",
              2000
            );
            this.$emit("actualizar");
          })
          .catch(() => {
            this.makeToast(
              "Error",
              "Hubo un error al eliminar la factura.",
              "danger",
              2000
            );
          });
      } catch (error) {
        this.makeToast(
          "Error",
          "Hubo un error al eliminar la factura.",
          "danger",
          2000
        );
      }
    },
    pagoConSaldo(should) {
      if (should) {
        this.pagoNuevo.PagoConSaldo = true;
        this.pagoNuevo.Importe = parseFloat(this.saldoAFavor);
        this.pagoNuevo.ImporteBloqueado = true;
      } else {
        this.pagoNuevo.PagoConSaldo = false;
        this.pagoNuevo.Importe = "";
        this.pagoNuevo.ImporteBloqueado = false;
      }
    },
    async updateFactura() {
      if (!this.infoFactura.FacturaID) {
        this.makeToast(
          "Actualizar factura",
          "Debe seleccionar una factura del listado.",
          "warning",
          3000
        );
        return;
      }

      if (
        !this.infoFactura.fechaVencimiento ||
        !this.infoFactura.descripcion ||
        !this.infoFactura.numeroFactura
      ) {
        this.makeToast(
          "Actualizar factura",
          "Ninguno de los campos debe ser un valor vacío",
          "warning",
          3000
        );
        return;
      }

      this.loading = true;

      try {
        await axiosInstance.patch(
          "/facturas",
          {
            factura: {
              NumFactura: this.infoFactura.numeroFactura,
              Vencimiento: this.infoFactura.fechaVencimiento,
              Concepto: this.infoFactura.descripcion,
              FacturaID: this.infoFactura.FacturaID,
            },
          },
          {
            headers: {
              authorization: localStorage.getItem("token"),
            },
          }
        );

        this.$emit("actualizar");
        this.makeToast(
          "Actualizar factura",
          "Factura actualizada",
          "success",
          3000
        );
      } catch (error) {
        this.makeToast(
          "Actualizar factura",
          error.response.data.msg || "Ocurrió un error en el servidor",
          "danger",
          3000
        );
      } finally {
        this.loading = false;
      }
    },
    parseDate(date) {
      return moment(date.split("T")[0]).format("DD/MM/YYYY");
    },
  },
  filters: {
    moneda: function (number) {
      return Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(number);
    },
  },
  watch: {
    isBusy() {
      // detecta cambios en listado de facturas
      this.resetDetails();
    },
  },
};
</script>

<style scoped>
td,
th {
  font-size: 0.8rem;
}

.table-sm td {
  padding: 0.1rem;
}

tr:hover {
  cursor: pointer;
}
</style>
