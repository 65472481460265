<template>
  <div>
    <div class="input-bar" v-if="soyAdmin">
      <b-form-file v-model="file" :state="Boolean(file)" placeholder="Seleccione o arrastre un archivo..."
        drop-placeholder="Arrastre l archivo..." accept=".jpg, .png, .gif, .pdf"
        :multiple="aceptarMultiple"></b-form-file>
      <b-button style="background-color: #355070" @click="guardarArchivo">Guardar</b-button>
    </div>
    <div v-if="soyAdmin" class="mt-3">Archivo seleccionado: {{ file ? file.name : "" }}</div>

    <b-container fluid class="p-4 bg-dark">
      <b-row>
        <b-col v-for="(archivo, index) in normalizedRutas" :key="index" class="mb-3">
          <a :href="archivo.archivo" target="_blank">
            <b-img thumbnail fluid
              :src="archivo.mimeType !== 'application/pdf' ? archivo.archivo : 'https://cdn.iconscout.com/icon/premium/png-256-thumb/pdf-file-rounded-square-50736.png'" />
          </a>
          <p class="text-center text-white">{{ archivo.archivoNombre }}</p>
          <div v-if="soyAdmin">
            <div class="mt-3 text-center">
              <b-button-group>
                <b-button variant="danger" size="sm" @click="borrarArchivo(archivo)">Eliminar</b-button>
              </b-button-group>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import api from "../../src/utils/api";
import Notification from "../utils/notification";
import { axiosInstance } from '../utils/axiosInstance';
export default {
  name: "ImageUploader",
  props: ["rutasArchivo", "aceptarMultiple", "documento_id", "tipo_documento"],
  data() {
    return {
      file: null,
    };
  },
  computed: {
    normalizedRutas() {
      if (this.tipo_documento) { // 'FACTURA', 'DESCUENTO', 'PAGO'
        return this.rutasArchivo.map((item) => {
          return {
            archivo: `${process.env.VUE_APP_JPR_API}archivos/obtener/${item.Path}`,
            path: item.Path,
            archivoId: item.ArchivoID,
            archivoNombre: item.ArchivoNombre,
            mimeType: item.MimeType
          };
        });
      }
      return [];
    },
    soyAdmin() {
      return sessionStorage.getItem('admin') === 'true';
    },
    usuario() {
      return sessionStorage.getItem('usuario_id');
    }
  },
  methods: {
    //TODO: handle single image again
    guardarArchivo() {
      this.file.forEach(async (archivo) => {
        try {
          const formData = new FormData();
          formData.append("archivo", archivo);
          formData.append("tipo_documento", this.tipo_documento);
          formData.append("documento_id", this.documento_id);
          formData.append("personal_id", sessionStorage.getItem('usuario_id'));

          const result = await axiosInstance.post('archivos/agregar', formData, {
            headers: {
              authorization: localStorage.getItem('token')
            }
          });

          if (result.statusText !== 'OK') {
            this.$notify({
              group: "foo",
              title: "Error al guardar el archivo",
              text: "No has subido ningun archivo o usaste un archivo incorrecto",
              type: "error",
            });
          } else if (result.data.affectedRows === 1) {
            this.$emit("reload-archivos");
            this.$notify({
              group: "foo",
              title: "Archivo guardado",
              text: "",
              type: "success",
            });
          }
        } catch (error) {
          this.$notify({
            group: "foo",
            title: "Error al guardar el archivo",
            text: "Error del servidor: " + error,
            type: "error",
          });
        }
      });
    },
    borrarArchivo(archivo) {
      console.log("ARCHIVO", archivo);
      if (confirm("Desea eliminar el archivo?")) {
        const url = `archivos/eliminar/${archivo.archivoId}/${archivo.path}`;
        api(
          "DELETE",
          url
        )
          .then(() => {
            Notification("success", "Borrar archivo", "archivo Eliminado");
          })
          .catch(() => {
            Notification("error", "Borrar archivo", "archivo NO Eliminado");
          });
        this.$emit("reload-archivos");
      }
    },
  },
};
</script>
  
<style>
.custom-file {
  margin-right: 10px;
}

.input-bar {
  display: flex;
  flex-direction: row;
}

.image-wrapper {
  margin: 30px auto;
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0px;
}

.image-item {
  max-height: 90px;
  max-width: 90px;
}

.image-wrapper>li {
  border: 1px black solid;
  margin: 1px;
  position: relative;
  height: 100px;
  width: 100px;
  justify-content: center;
  display: flex;
  align-items: center;
}

li>img {
  justify-content: center;
  display: flex;
  align-items: center;
}

.image-wrapper>li:hover {
  cursor: pointer;
}

.image-wrapper>li::after {
  content: "x";
  font-weight: 700;
  font-size: 19px;
  position: absolute;
  top: -7px;
  right: 3px;
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Buscar" !important;
}
</style>
  