<template>
  <div id="EstadoCuentaPDF" class="px-5" style="max-width: 793px;">
    <section>
      <article>
        <h2 class="text-center">Estado de cuenta</h2>
        <h3 class="text-left">JP Representaciones</h3>
        <h5 class="text-left">Cliente: {{ proveedores.length ? proveedores[0].usuario.razon_social : '' }}</h5>
        <h6 class="text-left">Fecha: {{ moment().locale('es').format('DD [de] MMM [de] YYYY') }}</h6>
      </article>
      <article v-for="(proveedor, index) of proveedores" :key="index">
        <h4 class="text-center">{{ proveedor.proveedor.nombre }}</h4>

        <h6 class="text-right">Saldo actual al proveedor: ${{ parseSaldoTotal(proveedor.proveedor.pedidos) }}</h6>

        <article v-for="(pedido, i) of proveedor.proveedor.pedidos" :key="i">
          <b-table-simple small bordered style="font-size: 10px;">
            <b-thead>
              <b-tr>
                <b-th>Factura</b-th>
                <b-th>Fecha</b-th>
                <b-th>Descripción</b-th>
                <b-th>Vencimiento</b-th>
                <b-th>Saldo Inicial</b-th>
                <b-th>Saldo Actual de la Factura</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(movimiento, ind) of parsePagos(pedido.Factura, proveedor.proveedor.nombre_corto, pedido.codigo)"
                :key="ind">
                <b-td>{{ movimiento.factura }}</b-td>
                <b-td>{{ movimiento.fecha }}</b-td>
                <b-td>{{ movimiento.descripcion }}</b-td>
                <b-td class="text-center">{{ movimiento.vencimiento }}</b-td>
                <b-td>
                  <div style="display: flex; justify-content: space-between;">
                    <div>
                      $
                    </div>
                    <div>
                      {{ movimiento.saldoInicial }}
                    </div>
                  </div>
                </b-td>
                <b-td>
                  <div style="display: flex; justify-content: space-between;">
                    <div>
                      $
                    </div>
                    <div>
                      {{ movimiento.saldoActual }}
                    </div>
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </article>
      </article>
    </section>
  </div>
</template>

<script>
import moment from 'moment';
import jsPDF from 'jspdf';
import writeXlsxFile from 'write-excel-file';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      moment,
      proveedores: []
    }
  },
  mounted() {
    document.getElementById('EstadoCuentaPDF').style.display = 'none';
  },
  methods: {
    printPDF(proveedores) {
      this.proveedores = proveedores;
      let doc = new jsPDF({ unit: 'px', hotfixes: ['px_scaling'] });
      document.getElementById('EstadoCuentaPDF').style.display = 'block';
      doc.html(document.getElementById("EstadoCuentaPDF"), {
        autoPaging: 'text',
        margin: [100, 0, 100, 0],
        callback: function (doc) {
          doc.save(`EstadoCuenta-${moment().format('DD/MM/YYYY')}.pdf`);
          document.getElementById('EstadoCuentaPDF').style.display = 'none';
          this.proveedores = [];
        },
      });
    },
    async printExcel(proveedores) {
      const data = [
        [
          {
            value: 'Estado de cuenta',
            fontWeight: 'bold'
          },
          {
            value: 'JP Representaciones',
            fontWeight: 'bold'
          },
          {
            value: `Cliente ${proveedores[0].usuario.razon_social}`,
            fontWeight: 'bold'
          }
        ],
        [
          {
            value: `Fecha: ${moment().locale('es').format('DD [de] MMM [de] YYYY')}`
          }
        ],
        [
          {
            value: ''
          }
        ]
      ];

      for (const proveedor of proveedores) {
        data.push([
          {
            value: proveedor.proveedor.nombre,
            fontWeight: 'bold'
          },
          {
            value: ''
          },
          {
            value: ''
          },
          {
            value: ''
          },
          {
            value: ''
          },
          {
            value: `Saldo actual al proveedor: ${this.parseSaldoTotal(proveedor.proveedor.pedidos)}`,
            fontWeight: 'bold'
          },
        ]);

        for (const pedido of proveedor.proveedor.pedidos) {
          data.push([
            {
              value: 'Factura',
              fontWeight: 'bold'
            },
            {
              value: 'Fecha',
              fontWeight: 'bold'
            },
            {
              value: 'Descripción',
              fontWeight: 'bold'
            },
            {
              value: 'Vencimiento',
              fontWeight: 'bold'
            },
            {
              value: 'Saldo Inicial',
              fontWeight: 'bold'
            },
            {
              value: 'Saldo Actual de la factura',
              fontWeight: 'bold'
            },
          ]);

          for (const movimiento of this.parsePagos(pedido.Factura, proveedor.proveedor.nombre_corto, pedido.codigo)) {
            data.push([
              {
                value: movimiento.factura,
                type: String,
              },
              {
                value: movimiento.fecha,
                type: String,
              },
              {
                value: movimiento.descripcion,
                type: String,
              },
              {
                value: movimiento.vencimiento,
                type: String,
              },
              {
                value: movimiento.saldoInicial,
                type: String,
              },
              {
                value: movimiento.saldoActual,
                type: String,
              },
            ])
          }

          data.push([
            {
              value: ''
            },
            {
              value: ''
            },
            {
              value: ''
            },
            {
              value: ''
            },
            {
              value: ''
            },
            {
              value: ''
            },
          ])
        }
      }

      try {
        await writeXlsxFile(data, { fileName: `EstadoCuenta-${moment().format('DD/MM/YYYY')}.xlsx`})
      } catch (error) {
        Swal.fire({ titleText: 'Error', text: 'Hubo un error al exportar a Excel', icon: 'error' });
      }
    },
    parsePagos(factura, proveedor, codigo) {
      const array = [];

      if (!factura || !proveedor || !codigo) {
        return array  
      }

      let saldo = parseFloat(factura.Total);

      const cargoFactura = factura.movimientos_cobranzas.find(p => !p.Pago && !p.Abono && p.Comentario.includes('Cargo por factura'));

      array.push({
        factura: factura.NumFactura,
        fecha: moment(cargoFactura.FechaAplicado).locale('es').format('DD-MMM-YY'),
        descripcion: `${proveedor} PEDIDO ${codigo}`,
        vencimiento: moment(factura.Vencimiento).format('DD/MM/YYYY'),
        saldoInicial: cargoFactura.Importe,
        saldoActual: saldo.toFixed(2)
      });

      array.push(...factura.descuentos_facturas.map(desc => {
        saldo = saldo - parseFloat(desc.Importe)
        return {
          factura: factura.NumFactura,
          fecha: moment(desc.FechaCreacion).locale('es').format('DD-MMM-YY'),
          descripcion: desc.Comentario || desc.TipoDescuento.descripcion,
          vencimiento: '',
          saldoInicial: desc.Importe,
          saldoActual: saldo.toFixed(2)
        }
      }));

      array.push(...factura.movimientos_cobranzas
        .filter(p => (p.Pago || p.Abono) && !p.Comentario.includes('Cargo por factura'))
        .map(p => {
          saldo = saldo + parseFloat(p.Importe);

          return {
            factura: factura.NumFactura,
            fecha: moment(p.FechaAplicado).locale('es').format('DD-MMM-YY'),
            descripcion: p.Comentario,
            vencimiento: '',
            saldoInicial: (parseFloat(p.Importe) * -1).toFixed(2),
            saldoActual: saldo.toFixed(2)
          }
        })
      )

      return array;
    },
    parseSaldoTotal(pedidos) {
      if (!pedidos || !pedidos.length) {
        return '0'
      }
      
      let saldo = 0;

      for (const pedido of pedidos) {
        if (!pedido.Factura) {
          continue
        }

        saldo = saldo + parseFloat(pedido.Factura.SaldoActual)
      }

      return saldo.toFixed(2);
    }
  }
}
</script>